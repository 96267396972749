.resumeText {
  padding: 1rem;
  font-size: 1.5rem;
}

.inputField {
  width: 19rem;
}

.inputField input {
  border-radius: 50rem !important;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 85vh;
  padding: 0 1rem;
}
