.ApplicationStatus {
  display: flex;
  flex-direction: column;
  padding: 2.75rem;
}

.status-header {
  font-size: 1.2rem;
}

.application-steps {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  margin-top: 2rem;
}

.bold {
  font-weight: bold;
}

.tick {
  margin-left: 0.8rem;
  position: relative;
  top: 0.1rem;
}