.errorContainer {
    position: relative;
    top: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.refreshBox {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    text-align: center;
}