.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    /* top: 8vh; */
    overflow-y: scroll;
    padding-bottom: 10vh;
    /* padding-top: 5vh; */
    margin-bottom: 1vh;
}

.text { 
    text-align: center;
    margin-top: 4rem;
    padding: 0 1rem;
}

.arrow svg {
    font-size: 3rem;
    transform: scaleX(-1) rotate(270deg);
    position: absolute;
    right: 10vw;
}

@media only screen and (min-width: 1280px) {
    .arrow svg {
        right: 2.5vw;
    }
}

@media only screen and (min-width: 667px) and (max-width: 800px) {
    .main {
        top: 5vh;
    }

    .arrow svg {
        right: 5vw;
    }
}

@media only screen and (min-width: 800px) and (max-width: 1200px) {
    .arrow svg {
        right: 5vw;
    }
}