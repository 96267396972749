.header {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  height: 4rem;
  font-weight: bold;
}

.menuHeader {
  display: flex;
  align-items: center;
  padding: 1rem 0rem;
  justify-content: center;
  width: 100%;
  background-color: white;
  font-weight: bold;
  box-shadow: 0 0.15rem 0.15rem 0 rgba(0, 0, 0, 0.1);
}

.backText {
  font-size: 1.15rem;
}

.header_name {
  position: relative;
  padding-right: 1rem;
}

.menuHeader .title {
  position: absolute;
}

.menuHeader .icon {
  margin-left: auto;
}

.headerTop {
  display: flex;
  padding: 0.5rem 0;
  background-color: #fff;
  justify-content: space-between;
  padding: 1rem;
  align-items: center;
}

.resumeBack {
  display: flex;
  align-items: center;
  margin-right: auto !important;
  margin-left: 0.5rem !important;
}

@media only screen and (min-width: 800px) {
  .header {
    margin: auto 18vw;
  }
}

@media only screen and (min-width: 1000px) {
  .header {
    margin: auto 30vw;
  }
}
