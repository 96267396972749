.App {
  background-color: #fbfcff;
}

@media only screen and (min-width: 800px) {
  .App {
    height: 100vh;
    margin: auto 18vw;
    box-shadow: 0px 4px 16px rgb(0 0 0 / 4%);
  }
}

@media only screen and (min-width: 1000px) {
  .App {
    height: 100vh;
    margin: auto 30vw;
    box-shadow: 0px 4px 16px rgb(0 0 0 / 4%);
  }
}

.css-jh47zj-MuiButtonBase-root-MuiButton-root {
  text-transform: none !important;
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 22px;
}

.backText {
  font-size: 1.15rem;
}
