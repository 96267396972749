.group {
    display: flex;
    flex-direction: column;
    width: 20rem;
}

.dollarSign:before {
    content:"$";
    position: relative;
    left: 1rem;
	top: 3.25rem;
}

.dollarSign input {
    padding-left: 2rem;
}