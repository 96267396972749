.photoContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.photo {
    margin-bottom: 1rem;
}

.photo img {
    flex: 50%;
    height: 8rem;
    width: 8rem;
    border-radius: 15%;
}

.photoDelete {
    position: relative !important;
    display: flex !important;
    bottom: 2rem !important;
    left: 6rem !important;
    background-color: #fff !important;
    border-radius: 50% !important;
}