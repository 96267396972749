.vertical {
    padding: 1rem;
    display: flex;
    flex-direction: column;
}

.vertical Button {
    margin: .5rem;
    width: 20rem;
    background-color: #fff;
    color: #111;
    justify-content: flex-start;
    box-shadow: 0.25rem 0.5rem 0.5rem rgb(0 0 0 / 8%);
    border: 2px solid #1976d2;
    /* border-radius: .5rem; */
}

.vertical Button.selected {
    background-color: #1976d2;
    color: #fff;
}

.radioWrapper {
    display: flex;
    justify-content: center;
    align-self: center;
    border-radius: 2rem;
    border: 1px solid #1976d2;
    color: #1976d2;
    padding: .5rem 0;
    margin-bottom: 1em;
    width: 18rem;
}

.radioWrapper:first-of-type {
    margin-top: 1rem;
}

.radioWrapper input {
    display: none;
}

.radioWrapper label {
    width: 100%;
    display: flex;
    justify-content: center;
}

.selected {
    background-color: #1976d2;
    color: #fff;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}