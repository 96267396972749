.marginBottomSmall {
    margin-bottom: 1rem;
}

.marginSmall {
    margin: 1rem !important;
}

.paddingSideSmall {
    padding: 0 1rem;
}

.hidden {
    display: none;
}