.help {
    position: fixed;
    background-color: aliceblue;
    height: 100%;
    top: 0;
    z-index: 101;
    color: #005280;
}

.helpHeader { 
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
}

.helpBody {
    padding: 0 1rem;
}