.rejection { 
    display: flex;
    flex-direction: column;
    align-items: center;
}

.rejectionTop {
    display: flex;
    align-items: center;
}

.rejection p {
    padding: 0 1rem;
    text-align: center;
}