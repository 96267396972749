.introduction {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  position: relative;
  top: 8vh;
  overflow-y: scroll;
  padding-bottom: 10vh;
  padding-top: 5vh;
}

.header {
  font-size: 1.2rem;
  margin: 1rem 0;
  margin-bottom: 1rem;
}

.text {
  line-height: 24px;
}

.sub-header {
  font-size: 1.2rem;
  padding: 2rem 0 2rem 0;
}

.list {
  display: flex;
  column-gap: 0.5rem;
  padding-top: 1.5rem;
}

.margin {
  margin-bottom: 2rem;
}
