.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    top: 8vh;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-bottom: 10vh;
    padding-top: 5vh;
}

.mainBody {
    display: flex;
    flex-direction: column;
}

.questionsBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    box-shadow: 0px 4px 16px rgb(0 0 0 / 4%);
    border-radius: 1rem;
    padding: 1rem;
    margin: 1rem 6vw;
}

.text, .stepNumber {
    align-self: center;
    font-weight: 400;
    font-size: 1rem;
    color: #8695a8;
    padding: 0 1em;
}

.help {
    border: 2px solid #5bc51a !important;
    border-radius: 2rem !important;
    color: #5bc51a !important;
    align-self: center !important;
    margin-bottom: 2rem !important;
    text-decoration: none !important;
    text-transform: none !important;
    background: none;
    font: inherit;
}

/* .help {
    text-align: center;
    margin: 1rem;
    color: #1976d2;
}

.help:visited {
    color: #1976d2
} */

/* .main Button {
    margin: .5rem auto;
    width: 20rem;
    border-radius: 2rem;
    padding: .5rem;
} */

@media only screen and (min-height: 1000px) {
    .main {
        top: 5vh;
    }
}