.buttonContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    row-gap: 1rem;
    align-items: center;
}

.containerWithTopMargin {
    margin-top: 5rem;
}

.startupContainer {
    position: relative;
    top: 5rem;
}

.button {
    border-radius: 24px !important;
    border-color: #0e97e3 !important;
    width: 18rem;
    align-self: center;
}

.buttonColorAgnostic {
    border-radius: 24px !important;
    width: 18rem;
    align-self: center;
}

.linkLike {
    border: none;
    background-color: transparent;
    color: #1976d2;
    text-decoration: underline;
    font-size: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.displacedMember {
    background-color: #1111 !important;
    border-radius: 0.5rem !important;
}

.previouslyEnteredClose {
    position: relative !important;
    top: 0.5rem !important;
    left: 0.5rem !important;
}

.photoButtonContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.photoButton {
    display: flex;
    color: #fff;
    padding: 0.5rem 1.375rem;
    background-color: #1976d2;
    border-radius: 24px !important;
    width: 15.25rem;
    height: 1.516rem;
    align-items: center;
    justify-content: center;
}

input[type="file"] {
    display: none;
}