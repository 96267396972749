.menu {
    z-index: 200;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    height: 100vh;
    position: fixed;
    width: 100vw;
}

.menuButton {
    display: inline-flex;
    position: relative;
    width: 100%;
    box-shadow: 0 .15rem .15rem 0 rgba(0, 0, 0, 0.05);
}

.chevron {
    margin-left: auto;
}

@media only screen and (min-width: 800px) {
    .menu {
        position: fixed;
        width: 9vw;
    }
}

@media only screen and (min-width: 1180px) {
    .menu {
        position: fixed;
        width: 40vw;
    }
}
