.referenceCard {
    background: #ffffff;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04);
    border-radius: 12px;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    margin-bottom: 2rem;
    width: 80%;
  }
  
.number {
    font-size: 1.8rem;
    line-height: 44px;
    color: #37414e;
    }

.label {
    font-size: 14px;
    line-height: 24px;
    color: #586575;
  }

