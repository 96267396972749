.application-reference {
  padding: 2rem;
  position: relative;
  top: 8vh;
  overflow-y: scroll;
  padding-bottom: 10vh;
  padding-top: 5vh;
}

.inputs {
  margin: 3rem 0 2rem;
}

.label {
  font-size: 14px;
  line-height: 24px;
  color: #586575;
}

.input-field {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input {
  border-color: #0e97e3 !important;
}

.reference-card {
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  margin-bottom: 2rem;
  width: 80%;
}

.number {
  font-size: 1.8rem;
  line-height: 44px;
  color: #37414e;
}