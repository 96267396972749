.loginHeader {
  background-color: #1976d2;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 15rem;
  padding-left: 1rem;
}


.loginHeader h1 {
  display: flex;
  justify-content: flex-start;
  margin-left: 1.85rem;
}

.circles {
  display: flex;
  flex-direction: row;
}

.circles div {
  height: 5rem;
  width: 5rem;
  border-radius: 50%;
}

.circles div:first-of-type {
  background: linear-gradient(
    -45deg,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );
  position: relative;
  left: 1rem;
}

.circles div:nth-of-type(2) {
  background: radial-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
  position: relative;
  right: 3rem;
}

.circles div:nth-of-type(3) {
  background: linear-gradient(
    45deg,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );
  position: relative;
  right: 7rem;
}

.loginBody {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loginBody h2 {
  align-self: flex-start;
  margin-left: 2rem;
  margin-bottom: 0;
}

.buttonContainer {
  margin-top: 1rem;
}

.buttonContainer,
.inputContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 2rem;
  color: #37414e;
  padding: 0 2rem;
  padding-top: 3rem;
}

.inputContainer {
  width: 20rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.buttonContainer Button {
  margin: 0.5rem auto;
  width: 20rem;
  border-radius: 2rem;
  padding: 0.5rem;
}

.buttonUnselected {
  background-color: #fff !important;
  color: #1976d2 !important;
  border: 2px solid #1976d2 !important;
  box-shadow: none !important;
}
