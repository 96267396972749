.ApplicationAgreement {
  padding: 2rem;
  position: relative;
  top: 8vh;
  overflow-y: scroll;
  padding-bottom: 10vh;
  padding-top: 5vh;
}

.app-question {
  margin-bottom: 3rem;
}

.buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1rem;
}

.application-buttons {
  border-radius: 24px !important;
  color: #1976d2 !important;
  border-color: #0e97e3 !important;
  width: 18rem;
}

.selected {
  color: #fff !important;
}

.card {
  margin-top: 3rem;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.04);
  border-radius: 16px;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
